import { useState, Fragment } from 'react';

import { Checkbox, FormLabel, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { COLLAPSED_ITEMS_LIMIT } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { useChangeChildTreeValue } from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/hooks/useChangeChildTreeValue';
import { useGroupValueByParent } from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/hooks/useGroupValueByParent';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';

const EMPTY_STRING_ARRAY: Array<string> = [];

const shouldRenderItem = (index: number, breakpoint: number, expanded: boolean): boolean =>
    index <= breakpoint - 1 || expanded;

const TrlKeys = {
    title: 'clusters.subindustry',
};

const SubIndustry: TranslatedComponent = ({ trls }) => {
    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[ClusterKey.SubIndustry]) || EMPTY_CLUSTER;
    const { groups: industryGroups } =
        useSelector((state) => state.searchClusters[ClusterKey.Industry]) || EMPTY_CLUSTER;
    const order = useSelector((state) => state.searchClustersOrder?.[ClusterKey.SubIndustry]) || EMPTY_STRING_ARRAY;

    const [expanded, setExpanded] = useState(false);

    const subIndustryLength = Object.values(groups).length;
    const breakpoint = subIndustryLength - COLLAPSED_ITEMS_LIMIT === 1 ? subIndustryLength : COLLAPSED_ITEMS_LIMIT;

    const applyFilter = useApplyFilter(ClusterKey.Industry);
    const subIndustryMap = useGroupValueByParent(groups, 'industryId', order);
    const onChangeSubIndustry = useChangeChildTreeValue(selectedValues, applyFilter);

    if (!subIndustryLength) {
        return null;
    }

    let commonIndex = 0;

    return (
        <FilterWrapper filterKey={ClusterKey.Industry} title={trls[TrlKeys.title]}>
            <FilterList expanded={expanded} len={subIndustryLength}>
                {Object.keys(subIndustryMap).map((parentId) => {
                    const parent = industryGroups[parentId];
                    if (!shouldRenderItem(commonIndex, breakpoint, expanded)) {
                        return null;
                    }
                    return (
                        <Fragment key={parentId}>
                            {parent && (
                                <>
                                    <FormLabel>{parent.title}</FormLabel>
                                    <VSpacing default={8} />
                                </>
                            )}
                            {Object.values(subIndustryMap[parentId]).map(({ id, title, count }) => {
                                if (!shouldRenderItem(commonIndex, breakpoint, expanded)) {
                                    return null;
                                }
                                commonIndex += 1;
                                return (
                                    <FilterItem
                                        key={id}
                                        left={
                                            <Checkbox
                                                value={id}
                                                onChange={({ target }) => {
                                                    onChangeSubIndustry(target.value);
                                                }}
                                                checked={selectedValues.includes(id)}
                                                dataQaCheckbox="serp__novafilter-item-text"
                                            />
                                        }
                                        title={title}
                                        count={count}
                                    />
                                );
                            })}
                        </Fragment>
                    );
                })}
            </FilterList>
            {subIndustryLength > breakpoint && (
                <LinkMore
                    length={subIndustryLength - breakpoint}
                    expanded={expanded}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                />
            )}
        </FilterWrapper>
    );
};

export default translation(SubIndustry);
