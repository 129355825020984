const useChangeParentTreeValue =
    (checkedValues: string[], setCheckedValuesWithOnChange: (newValues: string[]) => void) =>
    (id: string): void => {
        const haveChildrenForParent = checkedValues.some((item) => {
            const [parent] = item.split('.');
            return parent === id && item.includes('.');
        });
        // need clear children for selected parent
        const newCheckedValues = checkedValues.filter((item) => {
            const [parent] = item.split('.');
            return parent !== id;
        });
        if (haveChildrenForParent || !checkedValues.includes(id)) {
            newCheckedValues.push(id);
        }
        setCheckedValuesWithOnChange(newCheckedValues);
    };

export { useChangeParentTreeValue };
