import { useMemo, useState } from 'react';

import debounce from 'bloko/common/debounce';

import { MetroType } from 'src/types/search/common/clusters';

import {
    MetroDataProviderResponse,
    useMetroDataProvider,
} from 'src/components/Search/Vacancy/Filters/Metro/hooks/useMetroDataProvider';

interface MetroItem {
    id: string;
    color: string;
    title: string;
    type: MetroType;
    count: number;
}

type UseSearchMetroHook = () => {
    isSearchMetroEnabled: boolean;
    searchMetroList: MetroItem[];
    searchMetroDebounced: (searchText: string, onSuccess?: (isSearchMetroEnabled: boolean) => void) => void;
};

const useSearchMetro: UseSearchMetroHook = () => {
    const [searchMetroList, setSearchMetroList] = useState<MetroItem[]>([]);
    const [isSearchMetroEnabled, setIsSearchMetroEnabled] = useState(false);

    const dataProvider = useMetroDataProvider();

    const searchMetroDebounced = useMemo(
        () =>
            debounce(async (searchText: string, onSuccess?: (isSearchMetroEnabled: boolean) => void) => {
                let results: MetroDataProviderResponse;
                try {
                    results = await dataProvider(searchText.trim().toUpperCase());
                } catch (error) {
                    console.error(error);
                    return;
                }

                if (!results.items) {
                    return;
                }

                const list = results.items.map(({ text, id, color }) => {
                    return {
                        id,
                        color,
                        title: text,
                        // TODO: add «type» property to the response
                        type: !id.includes('.') ? MetroType.Line : MetroType.Station,
                        count: 0,
                    };
                });

                setSearchMetroList(list);
                setIsSearchMetroEnabled(searchText.length > 0);

                onSuccess && onSuccess(isSearchMetroEnabled);
            }, 400),
        [dataProvider, isSearchMetroEnabled]
    );

    return { isSearchMetroEnabled, searchMetroList, searchMetroDebounced };
};

export { useSearchMetro };
