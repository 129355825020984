import { useMemo, useState } from 'react';

import { Checkbox, fromTree, Link } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import FilterTreeSelector from 'src/components/Search/Common/Filters/TreeSelector';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { useChangeParentTreeValue } from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/hooks/useChangeParentTreeValue';
import { isCheckedOrIndeterminate } from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/utils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';

const EMPTY_STRING_ARRAY: Array<string> = [];

const TrlKeys = {
    title: 'resumeBuilder.industry',
    chooseMore: 'clusters.chooseMore',
};

const Industry: TranslatedComponent = ({ trls }) => {
    const industryTree = useSelector((state) => state.industriesTree);
    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[ClusterKey.Industry]) || EMPTY_CLUSTER;
    const { groups: subIndustryGroups } =
        useSelector((state) => state.searchClusters[ClusterKey.SubIndustry]) || EMPTY_CLUSTER;
    const order = useSelector((state) => state.searchClustersOrder?.[ClusterKey.Industry]) || EMPTY_STRING_ARRAY;

    const [isVisible, setVisible] = useState(false);

    const { items, breakpoint } = useFilterGroups(groups, selectedValues.length, order);
    const applyFilter = useApplyFilter(ClusterKey.Industry);
    const onChangeIndustry = useChangeParentTreeValue(selectedValues, applyFilter);

    const collection = useMemo(() => fromTree(industryTree), [industryTree]);
    const counts = useMemo(
        () =>
            Object.values({ ...groups, ...subIndustryGroups }).reduce(
                (acc, { id, count }) => {
                    acc[id] = count;
                    return acc;
                },
                {} as Record<string, number>
            ),
        [groups, subIndustryGroups]
    );

    return (
        <FilterWrapper filterKey={ClusterKey.Industry} title={trls[TrlKeys.title]}>
            <FilterList>
                {items.map(({ title, id }, index) => {
                    if (index > breakpoint - 1) {
                        return null;
                    }
                    const { checked, indeterminate } = isCheckedOrIndeterminate(id, selectedValues);
                    const group = groups[id];
                    return (
                        <FilterItem
                            key={id}
                            left={
                                <Checkbox
                                    value={id}
                                    onChange={({ target }) => {
                                        onChangeIndustry(target.value);
                                    }}
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    dataQaCheckbox="serp__novafilter-item-text"
                                />
                            }
                            title={title}
                            count={group?.count}
                            disabled={!group}
                        />
                    );
                })}
            </FilterList>
            <LinkMore onClick={() => setVisible(true)}>
                <Link>{trls[TrlKeys.chooseMore]}</Link>
            </LinkMore>
            <FilterTreeSelector
                title={trls[TrlKeys.title]}
                isVisible={isVisible}
                setVisible={setVisible}
                collection={collection}
                applyFilter={applyFilter}
                selectedValues={selectedValues}
                counts={counts}
            />
        </FilterWrapper>
    );
};

export default translation(Industry);
