import { useMemo } from 'react';

import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProvider, DataProviderItem, RemoteDataProviderResponse } from 'bloko/blocks/suggest/types';

import { updateUrl } from 'Modules/url';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey } from 'src/types/search/common/clusters';

export type MetroDataProviderItem = DataProviderItem & {
    id: string;
    color: string;
    line?: number;
};

export type MetroDataProvider = DataProvider<MetroDataProviderItem>;
export type MetroDataProviderResponse = RemoteDataProviderResponse<MetroDataProviderItem>;

const WILDCARD = '%QUERY%';
const REMOTE = `/autosuggest/multiprefix/v2`;

type UseMetroDataProviderHook = () => MetroDataProvider;

const useMetroDataProvider: UseMetroDataProviderHook = () => {
    const lang = useSelector((state) => state.langs)[0];
    const selectedArea = useSelector((state) => state.searchClusters[ClusterKey.Area])?.selectedValues[0];

    const remoteUrl = updateUrl(REMOTE, {
        d: `metro_${lang}_${selectedArea}`,
    });

    return useMemo(
        () => createRemoteDataProvider(`${remoteUrl}&q=${WILDCARD}`, WILDCARD),
        [remoteUrl]
    ) as MetroDataProvider;
};

export { useMetroDataProvider };
