const useChangeChildTreeValue =
    (checkedValues: string[], setCheckedValuesWithOnChange: (newValues: string[]) => void) =>
    (id: string): void => {
        // if we have parent,
        // need to delete current parent and push other children for deleted parent
        const [parent] = id.split('.');
        if (checkedValues.includes(parent)) {
            const filteredSelected = checkedValues.filter((item) => parent !== item);
            setCheckedValuesWithOnChange([...filteredSelected, ...[id]]);
            return;
        }
        // if we don't have this child
        if (!checkedValues.includes(id)) {
            // push new child
            setCheckedValuesWithOnChange([...checkedValues, id]);
            return;
        }
        // else just delete child
        const newSelected = checkedValues.filter((item) => item !== id);
        setCheckedValuesWithOnChange(newSelected);
    };

export { useChangeChildTreeValue };
